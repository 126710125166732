import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      loading: _vm.formLoading
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置 ")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref, index) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return _c("div", {
            key: index
          }, [key == "action" ? [_c("a", {
            on: {
              click: function click($event) {
                return _vm.handleAction("view", record);
              }
            }
          }, [_vm._v("详情")]), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleAction("log", record);
              }
            }
          }, [_vm._v("日志")])] : key == "checkstatus" ? [_c("span", {
            class: {
              "color-red": text == 2,
              "color-green": text == 1
            }
          }, [_vm._v(_vm._s(_vm.statusText(text)))])] : key == "certification" ? [_vm._v(" " + _vm._s(_vm.certificationText(text)) + " ")] : key == "playedamount" ? [_vm._v(" " + _vm._s(text || 0) + " ")] : [_vm._v(_vm._s(record[key] || "---"))]], 2);
        }
      };
    })], null, true)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  }), _c("DPopup", {
    attrs: {
      title: _vm.modalTitle,
      comVisible: _vm.modalVisible,
      isClosable: true,
      modalWidth: "800px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.modalVisible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [["log"].includes(_vm.action) ? _c("Log", {
          attrs: {
            editId: _vm.editId,
            action: _vm.action
          },
          on: {
            cancel: _vm.handleFormCancel
          }
        }) : _c("Form", {
          attrs: {
            editId: _vm.editId
          },
          on: {
            cancel: _vm.handleFormCancel,
            submit: _vm.handleFormSubmit
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };