import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer"
  }, [_vm.action == "view" ? _c("a", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["authentication:btn:edit"],
      expression: "['authentication:btn:edit']"
    }],
    staticClass: "btn-edit",
    on: {
      click: function click($event) {
        _vm.action = "edit";
      }
    }
  }, [_c("a-icon", {
    style: {
      fontSize: "30px"
    },
    attrs: {
      type: "form"
    }
  })], 1) : _vm.action == "edit" ? _c("a", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["authentication:btn:edit"],
      expression: "['authentication:btn:edit']"
    }],
    staticClass: "btn-edit",
    on: {
      click: function click($event) {
        _vm.action = "view";
      }
    }
  }, [_c("a-icon", {
    style: {
      fontSize: "30px"
    },
    attrs: {
      type: "rollback"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 140px;"
      },
      model: _vm.item,
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "用户"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["user", {
        rules: [{
          required: true,
          message: "请选择用户"
        }],
        initialValue: _vm.item.user
      }],
      expression: "[\n              'user',\n              {\n                rules: [{ required: true, message: '请选择用户' }],\n                initialValue: item.user,\n              },\n            ]"
    }],
    staticClass: "user-select",
    staticStyle: {
      width: "262px"
    },
    attrs: {
      mode: "multiple",
      dropdownClassName: "user-select",
      allowClear: true,
      "show-search": "",
      labelInValue: "",
      placeholder: "请输入昵称/UID进行搜索",
      "filter-option": false,
      disabled: _vm.editId ? true : false,
      loading: _vm.selectLoading
    },
    on: {
      search: _vm.handleSearch,
      change: _vm.handleChange,
      popupScroll: _vm.handlePopupScroll
    }
  }, [_vm.selectLoading ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.useConnectLIst, function (d) {
    return _c("a-select-option", {
      key: d.uid,
      staticClass: "user-select-item",
      attrs: {
        title: d.username,
        value: d.uid
      }
    }, [_vm._v(" " + _vm._s(d.username) + " ")]);
  })], 2)], 1), _vm.action != "add" ? _c("a-form-item", {
    attrs: {
      label: "注册邮箱"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.item.email))])]) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "用户等级"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["usergrade", {
        rules: [{
          required: true,
          message: "请选择用户等级"
        }],
        initialValue: _vm.item.usergrade
      }],
      expression: "[\n              'usergrade',\n              {\n                rules: [{ required: true, message: '请选择用户等级' }],\n                initialValue: item.usergrade,\n              },\n            ]"
    }],
    attrs: {
      disabled: _vm.isDisabled
    }
  }, _vm._l(_vm.usergradeList, function (u) {
    return _c("a-select-option", {
      key: u.id,
      attrs: {
        value: u.value
      }
    }, [_vm._v(" " + _vm._s(u.value) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "认证昵称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["attestationname", {
        rules: [{
          required: true,
          message: "请输入认证昵称"
        }],
        initialValue: _vm.item.attestationname
      }],
      expression: "[\n              'attestationname',\n              {\n                rules: [{ required: true, message: '请输入认证昵称' }],\n                initialValue: item.attestationname,\n              },\n            ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      autoComplete: "off",
      placeholder: "请输入认证昵称",
      disabled: _vm.isDisabled
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "认证类型"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["certification", {
        rules: [{
          required: true,
          message: "请选择选择认证类型"
        }],
        initialValue: _vm.item.certification
      }],
      expression: "[\n              'certification',\n              {\n                rules: [{ required: true, message: '请选择选择认证类型' }],\n                initialValue: item.certification,\n              },\n            ]"
    }],
    attrs: {
      disabled: _vm.isDisabled
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("个人认证")])]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", [_vm._v("项目认证")])])], 1)], 1), _vm.form.getFieldValue("certification") == 2 ? [_c("a-form-item", {
    attrs: {
      label: "项目官网"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["officialurl", {
        rules: [{
          // type: 'url',
          required: true,
          message: "请输入项目官网"
        }],
        initialValue: _vm.item.officialurl
      }],
      expression: "[\n                'officialurl',\n                {\n                  rules: [\n                    {\n                      // type: 'url',\n                      required: true,\n                      message: '请输入项目官网',\n                    },\n                  ],\n                  initialValue: item.officialurl,\n                },\n              ]"
    }],
    attrs: {
      placeholder: "请输入官网地址",
      disabled: _vm.isDisabled,
      autoComplete: "off"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "项目官方邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["officialemail", {
        rules: [{
          type: "email",
          required: true,
          message: "请输入邮箱地址"
        }],
        initialValue: _vm.item.officialemail
      }],
      expression: "[\n                'officialemail',\n                {\n                  rules: [\n                    {\n                      type: 'email',\n                      required: true,\n                      message: '请输入邮箱地址',\n                    },\n                  ],\n                  initialValue: item.officialemail,\n                },\n              ]"
    }],
    attrs: {
      type: "email",
      placeholder: "请输入邮箱地址",
      disabled: _vm.isDisabled,
      autoComplete: "off"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "项目钱包地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["officialaddress", {
        rules: [{
          required: true,
          message: "请输入钱包地址"
        }],
        initialValue: _vm.item.officialaddress
      }],
      expression: "[\n                'officialaddress',\n                {\n                  rules: [{ required: true, message: '请输入钱包地址' }],\n                  initialValue: item.officialaddress,\n                },\n              ]"
    }],
    attrs: {
      placeholder: "请输入钱包地址",
      disabled: _vm.isDisabled,
      autoComplete: "off"
    }
  })], 1)] : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "附件"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["pictures", {
        rules: [{
          required: false,
          message: "请上传图片"
        }],
        initialValue: _vm.item.pictures
      }],
      expression: "[\n              'pictures',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: '请上传图片',\n                  },\n                ],\n                initialValue: item.pictures,\n              },\n            ]"
    }],
    attrs: {
      disabled: _vm.isDisabled,
      listType: "picture-card",
      max: 10,
      memorySize: "5120kb",
      accept: ".jpg,.jpeg,.png"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["memo", {
        rules: [{
          type: "string",
          max: 150,
          message: "最多只能输入150个字"
        }],
        initialValue: _vm.item.memo
      }],
      expression: "[\n              'memo',\n              {\n                rules: [\n                  {\n                    type: 'string',\n                    max: 150,\n                    message: '最多只能输入150个字',\n                  },\n                ],\n                initialValue: item.memo,\n              },\n            ]"
    }],
    attrs: {
      placeholder: "",
      autoComplete: "off",
      maxLength: 150
    }
  })], 1), _vm.action == "view" ? _c("a-form-item", {
    attrs: {
      label: "状态"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["checkstatus", {
        rules: [{
          required: true,
          message: "请选择状态"
        }],
        initialValue: [1, 2].includes(_vm.item.checkstatus) ? _vm.item.checkstatus : null
      }],
      expression: "[\n              'checkstatus',\n              {\n                rules: [{ required: true, message: '请选择状态' }],\n                initialValue: [1, 2].includes(item.checkstatus)\n                  ? item.checkstatus\n                  : null,\n              },\n            ]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("通过")])]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", [_vm._v("驳回")])])], 1)], 1) : _vm._e()], 2), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _vm.action == "add" ? _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.action == "add" && _vm.disabledLoading
    },
    on: {
      click: function click($event) {
        return _vm.submit("add");
      }
    }
  }, [_vm._v("创建")]) : _vm._e(), _vm.action == "edit" ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["authentication:btn:edit"],
      expression: "['authentication:btn:edit']"
    }],
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.action == "edit" && _vm.disabledLoading,
      disabled: _vm.disabledLoading && _vm.action == "check"
    },
    on: {
      click: function click($event) {
        return _vm.submit("edit");
      }
    }
  }, [_vm._v("保存")]) : _vm._e(), _vm.action == "view" ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["authentication:btn:check"],
      expression: "['authentication:btn:check']"
    }],
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: function click($event) {
        return _vm.submit("view");
      }
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)])], 1)]), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible && _vm.previewType == "pic",
      previewImage: _vm.previewSource
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };