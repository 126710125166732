import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
import { AuthenticationApi, WhitelistApi, DcVideoApi } from "@/api";
import Mixin from "@/utils/mixin";
import PreviewMixin from "@/utils/previewMixin";
import UploadImg from "@/components/UploadImg";
import debounce from "lodash/debounce";
export default {
  name: "authenticationForm",
  components: {
    UploadImg: UploadImg
  },
  props: {
    editId: [String, Number]
  },
  mixins: [Mixin, PreviewMixin],
  data: function data() {
    this.fetchGetUseConnectLIst = debounce(this.getUseConnectLIst, 800);
    this.handlePopupScroll = debounce(this.handlePopupScroll, 800);
    return {
      form: this.$form.createForm(this),
      spinning: false,
      disabledLoading: false,
      item: {},
      useConnectLIst: [],
      usergradeList: [{
        id: 0,
        value: "无评级"
      }, {
        id: 1,
        value: "S"
      }, {
        id: 2,
        value: "A"
      }, {
        id: 3,
        value: "B"
      }, {
        id: 4,
        value: "C"
      }],
      uloading: false,
      timer: null,
      action: this.editId ? "view" : "add",
      templates: [],
      // 模板列表
      templateCode: null,
      // 模板代码
      page: 1,
      size: 10,
      name: "",
      pages: 0,
      selectLoading: false,
      total: 0
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.action == "view";
    } // status(){
    //   return this.item.status || 1;
    // }
  },
  created: function created() {
    // console.log(this.status, this.editId);
    if (this.editId) {
      this.find();
      // this.loadTemplates();
    }
  },

  methods: {
    // 下拉框筛选
    // filterOption(input, option) {
    //   return (
    //     option.componentOptions.children[0].text
    //       .toLowerCase()
    //       .indexOf(input.toLowerCase()) >= 0
    //   );
    // },
    // handleReason(val) {
    //   this.templateCode = val;
    //   this.form.resetFields(["reason"]);
    // },
    // loadTemplates() {
    //   this.spinning = true;
    //   DcVideoApi.rejectreason()
    //     .then((res) => {
    //       this.spinning = false;
    //       this.templates = res;
    //     })
    //     .catch(() => {});
    // },
    find: function find() {
      var _this = this;
      this.disabledLoading = true;
      this.spinning = true;
      AuthenticationApi.find({
        id: this.editId
      }).then(function (res) {
        res.user = [{
          key: res.userid,
          label: res.username
        }];
        _this.item = res;
        _this.disabledLoading = false;
      }).catch(function (err) {
        _this.toast(err || "接口报错", "error");
      }).finally(function () {
        _this.spinning = false;
      });
    },
    cancle: function cancle() {
      this.$emit("cancel");
    },
    submit: function submit(action) {
      var _this2 = this;
      this.action = action;
      if (this.disabledLoading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.disabledLoading = true;
          var params = {};
          var method = _this2.editId ? "update" : "add";
          params = _objectSpread({}, values);
          if (values.user) {
            params.userids = values.user.map(function (item) {
              return item.key;
            });
            delete params.user;
          }
          if (action == "view") {
            var fillable = ["checkstatus", "memo"];
            for (var field in params) {
              if (!fillable.includes(field)) delete params[field];
            }
          }
          var req;
          if (_this2.editId) {
            params.id = _this2.editId;
            if (action == "view") {
              method = "updateStatus";
            }
          }
          req = AuthenticationApi[method](params);
          req.then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "操作失败", "error");
          }).finally(function () {
            setTimeout(function () {
              _this2.disabledLoading = false;
            }, 1000);
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    // handleSearch(val) {
    //   this.uloading = true;
    //   if (this.timer) {
    //     clearTimeout(this.timer);
    //     this.timer = null;
    //   }
    //   this.timer = setTimeout(() => {
    //     if (!val) {
    //       this.userList = [];
    //       this.uloading = false;
    //       return;
    //     }
    //     WhitelistApi.searchUsername({ name: val })
    //       .then((res) => {
    //         this.userList = res || [];
    //       })
    //       .catch(() => {
    //         this.userList = [];
    //       })
    //       .finally(() => {
    //         this.uloading = false;
    //       });
    //   }, 500);
    // },
    getUseConnectLIst: function getUseConnectLIst(parmas) {
      var _this3 = this;
      if (this.selectLoading) return;
      this.selectLoading = true;
      AuthenticationApi.userList(parmas).then(function (res) {
        var _res$list;
        if (((_res$list = res.list) === null || _res$list === void 0 ? void 0 : _res$list.length) == 0) return;
        if (_this3.editId) {
          var newData = res.list.filter(function (item) {
            return item.hooduseruid !== _this3.currentFormData.uid;
          });
          // console.log('??newdata',newData);
          _this3.useConnectLIst = _this3.useConnectLIst.concat(newData);
        } else {
          _this3.useConnectLIst = _this3.useConnectLIst.concat(res.list);
        }
        _this3.total = res.total;
        _this3.pages = res.pages;
      }).catch(function () {
        _this3.page = _this3.page > 1 ? _this3.page - 1 : 1;
      }).finally(function () {
        _this3.selectLoading = false;
      });
    },
    handleSearch: function handleSearch(value) {
      this.useConnectLIst = [];
      this.name = value;
      this.page = 1;
      if (!value.trim()) return;
      this.querypageFn();
    },
    handleChange: function handleChange(value) {
      console.log(value);
      this.value = value;
    },
    handlePopupScroll: function handlePopupScroll(e) {
      var target = e.target;
      var scrollTop = target.scrollTop,
        scrollHeight = target.scrollHeight,
        clientHeight = target.clientHeight;
      if (scrollTop + 2 + clientHeight >= scrollHeight) {
        if (this.total > this.useConnectLIst.length) {
          this.handlePagination();
        }
      }
    },
    handlePagination: function handlePagination() {
      if (this.selectLoading) return;
      this.page++;
      this.querypageFn();
    },
    querypageFn: function querypageFn() {
      this.fetchGetUseConnectLIst({
        page: this.page,
        size: this.size,
        name: this.name
      });
    }
  }
};